/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r},${g},${b},${opacity})`;
};

export default makeStyles(theme => ({
  background: {
    backgroundSize: 'cover',
    filter: 'blur(8px)',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: -1,
  },
  description: {
    color: theme.palette.primary.dark,
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.15rem',
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.dark,
    height: 1,
    marginBottom: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.up('md')]: {
      padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(8, 6)),
    },
    [theme.breakpoints.up('lg')]: {
      padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(8, 12)),
    },
    background: ({ backgroundColorFrom, backgroundColorTo, backgroundOpacity }) => (
      `linear-gradient(
        90deg,
        ${backgroundOpacity === 1
          ? backgroundColorFrom
          : convertHexToRGBA(`${backgroundColorFrom}`, backgroundOpacity)},
        ${backgroundOpacity === 1
          ? backgroundColorTo
          : convertHexToRGBA(`${backgroundColorTo}`, backgroundOpacity)}
      )`
    ),
    lineHeight: 0,
    margin: '0 auto',
    overflow: 'hidden',
    padding: ({ disablePadding }) => (disablePadding ? 0 : theme.spacing(8, 4)),
    position: 'relative',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
}));
