/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import { useMediaQueryTheme } from 'hooks';
import useStyles from './CommonProblems.styles';

const CommonProblems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isWidthUpLg = useMediaQueryTheme('lg');

  return (
    <Container className={classes.root} maxWidth="lg">
      <Grid
        alignItems="center"
        className={classes.grid}
        container
        direction="row"
        justifyContent="center"
        spacing={3}
      >
        <Grid
          item
          lg={7}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.1.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-1.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.1.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.1.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={5}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.2.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-2.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.2.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.2.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.3.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-3.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.3.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.3.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.4.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-4.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.4.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.4.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.5.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-5.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.5.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.5.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.6.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-6.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.6.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.6.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
        >
          <Paper
            className={classes.paper}
            elevation={10}
          >
            <StaticImage
              alt={t('routes.home.commonProblems.items.7.title')}
              className={classes.paperImg}
              height={64}
              src="../../assets/img/home-commonProblems-7.png"
              width={64}
            />
            <Typography variant="h4">
              {t('routes.home.commonProblems.items.7.title')}
            </Typography>
            <Typography component="div" variant="body1">
              <div dangerouslySetInnerHTML={{
                __html:
              t('routes.home.commonProblems.items.7.description'),
              }}
              />
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommonProblems;
