/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  grid: {
    '& > div': {
      // minHeight: 552,
    },
  },
  paper: {
    '& p': {
      fontStyle: 'italic',
      margin: theme.spacing(2, 0),
      overflow: 'auto',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(3, 5),
  },
  paperImg: {
    marginBottom: theme.spacing(2),
  },
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1),
    },
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: '0 auto',
    padding: theme.spacing(1, 1),
  },
}));
