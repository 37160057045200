/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import useStyles from './Section.styles';

const Section = ({
  backgroundColorFrom,
  backgroundColorTo,
  backgroundImage,
  backgroundOpacity,
  content,
  description,
  disablePadding,
  id,
  title,
}) => {
  const classes = useStyles({
    backgroundColorFrom,
    backgroundColorTo,
    backgroundOpacity,
    disablePadding,
  });

  return (
    <section className={classes.root} id={id}>
      {backgroundImage && (
        <img
          alt="planes-background"
          className={classes.background}
          loading="lazy"
          src={backgroundImage}
        />
      )}
      {title && (
        <>
          <Divider className={classes.divider} />
          <Typography className={classes.title} variant="h3">{title}</Typography>
        </>
      )}
      {description && (
        <Container maxWidth="lg">
          <Typography className={classes.description} variant="body1">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
        </Container>
      )}
      {content}
    </section>
  );
};

Section.defaultProps = {
  backgroundColorFrom: 'inherit',
  backgroundColorTo: 'inherit',
  backgroundImage: null,
  backgroundOpacity: 1,
  content: null,
  description: null,
  disablePadding: false,
  id: 'section',
  title: null,
};

Section.propTypes = {
  backgroundColorFrom: PropTypes.string,
  backgroundColorTo: PropTypes.string,
  backgroundImage: PropTypes.node,
  backgroundOpacity: PropTypes.number,
  content: PropTypes.node,
  description: PropTypes.node,
  disablePadding: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default Section;
