/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    margin: '0 auto',
    maxWidth: 1920,
  },
  grid: {
    flexGrow: 1,
    padding: theme.spacing(4, 0, 6),
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // sin reveal poner al 100%
    minHeight: 0,
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up('sm')]: {
      minHeight: 212,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 186,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 186,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: 186,
    },
  },
  paperImg: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    marginBottom: theme.spacing(1),
  },
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
    },
    padding: theme.spacing(1, 4),
    position: 'relative',
  },
}));
