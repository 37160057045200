import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ROUTES } from 'assets/data/actpsicologos-app';
import useStyles from './HowWeOperate.styles';

const HowWeOperate = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid className={classes.grid} container spacing={4}>
        <Grid item lg={3} sm={6} xs={12}>
          <Fade bottom delay={0} ssrReveal>
            <Paper className={classes.paper} elevation={1}>
              <StaticImage
                alt={t('routes.home.howWeOperate.items.1.title')}
                className={classes.paperImg}
                height={64}
                src="../../assets/img/home-howWeOperate-1.png"
                width={64}
              />
              <Typography className={classes.paperTitle} variant="h4">
                {t('routes.home.howWeOperate.items.1.title')}
              </Typography>
              <Typography variant="body2">
                {t('routes.home.howWeOperate.items.1.description')}
              </Typography>
            </Paper>
          </Fade>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <Fade bottom delay={200} ssrReveal>
            <Paper className={classes.paper} elevation={1}>
              <StaticImage
                alt={t('routes.home.howWeOperate.items.2.title')}
                className={classes.paperImg}
                height={64}
                src="../../assets/img/home-howWeOperate-2.png"
                width={64}
              />
              <Typography className={classes.paperTitle} variant="h4">
                {t('routes.home.howWeOperate.items.2.title')}
              </Typography>
              <Typography variant="body2">
                {t('routes.home.howWeOperate.items.2.description')}
              </Typography>
            </Paper>
          </Fade>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <Fade bottom delay={400} ssrReveal>
            <Paper className={classes.paper} elevation={1}>
              <StaticImage
                alt={t('routes.home.howWeOperate.items.3.title')}
                className={classes.paperImg}
                height={64}
                src="../../assets/img/home-howWeOperate-3.png"
                width={64}
              />
              <Typography className={classes.paperTitle} variant="h4">
                {t('routes.home.howWeOperate.items.3.title')}
              </Typography>
              <Typography variant="body2">
                {t('routes.home.howWeOperate.items.3.description')}
              </Typography>
            </Paper>
          </Fade>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <Fade bottom delay={600} ssrReveal>
            <Paper className={classes.paper} elevation={1}>
              <StaticImage
                alt={t('routes.home.howWeOperate.items.4.title')}
                backgroundColor="#fff"
                className={classes.paperImg}
                height={64}
                loading="eager"
                src="../../assets/img/home-howWeOperate-4.png"
                width={64}
              />
              <Typography className={classes.paperTitle} variant="h4">
                {t('routes.home.howWeOperate.items.4.title')}
              </Typography>
              <Typography variant="body2">
                {t('routes.home.howWeOperate.items.4.description')}
              </Typography>
            </Paper>
          </Fade>
        </Grid>
      </Grid>
      <Button color="primary" href={ROUTES.PROCEDURE} size="large" variant="contained">
        {t('routes.home.howWeOperate.readMore')}
      </Button>
    </Container>
  );
};

export default HowWeOperate;
