import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'gatsby';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import emailjs from 'emailjs-com';

import { ROUTES } from 'assets/data/actpsicologos-app';
import AppLegalInfo from 'app/AppLegalInfo';
import Notice from 'components/Notice/Notice';
import useStyles from './Contact.styles';

const HomeContact = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [noticeEmailSent, setNoticeEmailSent] = useState();
  const { control, formState: { errors }, getValues, handleSubmit, reset } = useForm({
    defaultValues: {
      acceptPolicy: false,
      email: '',
      name: '',
      phone: '',
    },
  });

  const onSubmit = data => {
    emailjs.send('service_o1u1g7m', 'template_I8zrVmus', data, 'user_gulh3U6n8De6QGDHs78Cl')
      .then(result => {
        setNoticeEmailSent('success');
        reset();
      },
      error => {
        setNoticeEmailSent('error');
        reset();
      });
  };

  const renderEmailSentNotice = () => {
    if (!noticeEmailSent) {
      return null;
    }
    if (noticeEmailSent === 'error') {
      return (
        <Notice
          description={t('base.emailSent.error.description')}
          title={t('base.emailSent.error.title')}
          type="error"
        />
      );
    }
    return (
      <Notice
        description={t('base.emailSent.success.description')}
        title={t('base.emailSent.success.title')}
        type="success"
      />
    );
  };

  let errorPhoneEmail;
  if (errors.email) {
    errorPhoneEmail = errors.email.type === 'pattern'
      ? t('forms.homeContact.email.validations.format')
      : t('routes.home.contact.extraValidation');
  }

  return (
    <Box className={classes.root} id="contacta">
      <Container maxWidth="sm">
        {renderEmailSentNotice()}
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.form}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name}
                  helperText={errors.name && t('forms.homeContact.name.validations.required')}
                  label={t('forms.homeContact.name.label')}
                  variant="outlined"
                />
              )}
              rules={{
                required: true,
              }}
            />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={Boolean(errorPhoneEmail)}
                  helperText={errorPhoneEmail}
                  label={t('forms.homeContact.email.label')}
                  variant="outlined"
                />
              )}
              rules={{
                pattern: /^\S+@\S+\.\S+$/,
                validate: () => {
                  const { email, phone } = getValues();
                  return Boolean(email || phone);
                },
              }}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.phone}
                  helperText={errors.phone && t('forms.homeContact.phone.validations.format')}
                  label={t('forms.homeContact.phone.label')}
                  variant="outlined"
                />
              )}
              rules={{
                pattern: /^\+?[0-9]{3}-?[0-9]{6,12}$/,
              }}
            />
            <AppLegalInfo />
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Controller
                      control={control}
                      name="acceptPolicy"
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={e => field.onChange(e.target.checked)}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  )}
                  label={(
                    <Box>
                      <Trans
                        i18nKey="forms.contact.policy.label"
                        values={{}}
                      >
                        {[
                          <Link
                            key={0}
                            className={classes.link}
                            target="_blank"
                            to={ROUTES.PRIVACY_POLICY}
                          />,
                        ]}
                      </Trans>
                    </Box>
                  )}
                />
              </FormGroup>
              {errors.acceptPolicy && (
                <FormHelperText error>
                  {t('forms.homeContact.policy.validations.required')}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              className={classes.button}
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              {t('routes.home.contact.submit')}
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default HomeContact;
