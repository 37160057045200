/* eslint-disable react/no-danger */
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import useStyles from './WhatWeContribute.styles';

const WhatWeContribute = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="xl">
      <Grid className={classes.grid} container spacing={2}>
        <Grid item md={6} xl={3} xs={12}>
          <Paper
            className={clsx(
              classes.paper,
              classes.paperBackground2Light,
            )}
            elevation={1}
          >
            <Box className={classes.paperContent}>
              <Typography variant="h4">
                {t('routes.home.whatWeContribute.items.1.title')}
              </Typography>
              <Divider classes={{ root: classes.divider }} />
              <Typography component="div" variant="body1">
                <div dangerouslySetInnerHTML={{
                  __html: t('routes.home.whatWeContribute.items.1.description'),
                }}
                />
              </Typography>
            </Box>
            <StaticImage
              alt={t('routes.home.whatWeContribute.items.1.title')}
              className={classes.paperImg}
              src="../../assets/img/home-whatWeContribute-1.jpg"
            />
          </Paper>
        </Grid>
        <Grid item md={6} xl={3} xs={12}>
          <Paper
            className={clsx(
              classes.paper,
              classes.paperBackground2,
            )}
            elevation={1}
          >
            <Box className={classes.paperContent}>
              <Typography variant="h4">
                {t('routes.home.whatWeContribute.items.2.title')}
              </Typography>
              <Divider classes={{ root: classes.divider }} />
              <Typography component="div" variant="body1">
                <div dangerouslySetInnerHTML={{
                  __html: t('routes.home.whatWeContribute.items.2.description'),
                }}
                />
              </Typography>
            </Box>
            <StaticImage
              alt={t('routes.home.whatWeContribute.items.2.title')}
              className={classes.paperImg}
              src="../../assets/img/home-whatWeContribute-2.jpg"
            />
          </Paper>
        </Grid>
        <Grid item md={6} xl={3} xs={12}>
          <Paper
            className={clsx(
              classes.paper,
              classes.paperBackground2Light,
            )}
            elevation={1}
          >
            <Box className={classes.paperContent}>
              <Typography variant="h4">
                {t('routes.home.whatWeContribute.items.3.title')}
              </Typography>
              <Divider classes={{ root: classes.divider }} />
              <Typography component="div" variant="body1">
                <div dangerouslySetInnerHTML={{
                  __html: t('routes.home.whatWeContribute.items.3.description'),
                }}
                />
              </Typography>
            </Box>
            <StaticImage
              alt={t('routes.home.whatWeContribute.items.3.title')}
              className={classes.paperImg}
              src="../../assets/img/home-whatWeContribute-3.jpg"
            />
          </Paper>
        </Grid>
        <Grid item md={6} xl={3} xs={12}>
          <Paper
            className={clsx(
              classes.paper,
              classes.paperBackground1Light,
            )}
            elevation={1}
          >
            <Box className={classes.paperContent}>
              <Typography variant="h4">
                {t('routes.home.whatWeContribute.items.4.title')}
              </Typography>
              <Divider classes={{ root: classes.divider }} />
              <Typography component="div" variant="body1">
                <div dangerouslySetInnerHTML={{
                  __html: t('routes.home.whatWeContribute.items.4.description'),
                }}
                />
              </Typography>
            </Box>
            <StaticImage
              alt={t('routes.home.whatWeContribute.items.4.title')}
              className={classes.paperImg}
              src="../../assets/img/home-whatWeContribute-4.jpg"
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhatWeContribute;
