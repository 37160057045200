/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  grid: {
    flexGrow: 1,
    padding: theme.spacing(0, 0, 5),
  },
  paper: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 286,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 382,
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: 266,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // sin reveal poner al 100%
    minHeight: 0,
    padding: theme.spacing(2),
  },
  paperImg: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    marginBottom: theme.spacing(1),
  },
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 12),
    },
    margin: '0 auto',
    padding: theme.spacing(4, 4),
  },
}));
