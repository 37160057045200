/* eslint-disable no-dupe-keys */
import { alpha, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  content: {
    '& h1': {
      lineHeight: '110%',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(8),
      },
    },
    '& h2': {
      fontSize: '1.3rem',
      fontWeight: 400,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(4),
      },
    },
    '& h2:first-of-type': {
      fontSize: '1.6rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
        fontWeight: 300,
      },
    },
    alignItems: 'center',
    color: theme.palette.primary.dark,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    // 4 (padding de abajo) * 8 * 2
    height: 'calc(100% - 64px)',
    padding: theme.spacing(4),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 412,
      padding: theme.spacing(6, 12),
    },
    [`${theme.breakpoints.up('md')} and (orientation: landscape)`]: {
    },
    [theme.breakpoints.up('lg')]: {
      // 12 (padding de abajo) * 8 * 2
      height: 'calc(100% - 192px)',
      maxWidth: 512,
      padding: theme.spacing(12, 12),
    },
    [theme.breakpoints.up('xl')]: {
      // 16 (padding de abajo) * 8 * 2
      height: 'calc(100% - 256px)',
      maxWidth: 612,
      padding: theme.spacing(16, 12),
    },
  },
  contentBottom: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentButton: {
    '& button': {
      '&:hover': {
        background: '#C0B2AB',
      },
      background: '#9F918D',
    },
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  contentTop: {
    flex: 1,
  },
  imgMd: {
    height: 'calc(100vh - 164px)',
    left: 0,
    minHeight: 720,
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  imgXs: {
    height: 'calc(100vh - 56px)',
    minHeight: 540,
    objectFit: 'cover',
    opacity: 0.3,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)',
    },
  },
  root: {
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 164px)',
    },
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      height: 'calc(100vh - 48px)',
    },
    height: 'calc(100vh - 56px)',
    position: 'relative',
  },
}));
