/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  grid: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0, 6),
    },
  },
  paper: {
    '& h5': {
      fontStyle: 'italic',
      marginBottom: 12,
      overflow: 'hidden',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 412,
    },
    padding: theme.spacing(2),
  },
  root: {
    alignItems: 'center',
    margin: '0 auto',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2),
    },
  },
}));
