import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ROUTES } from 'assets/data/actpsicologos-app';
import useStyles from './Plans.styles';

const Plans = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md">
      <Box className={classes.content}>
        <Grid className={classes.grid} container spacing={8}>
          <Grid item sm={6} xs={12}>
            <Fade bottom delay={0} ssrReveal>
              <Paper className={classes.paper} elevation={4}>
                <StaticImage
                  alt={t('routes.home.plans.items.1.title')}
                  className={classes.paperImg}
                  height={64}
                  src="../../assets/img/home-plans-1.png"
                  width={64}
                />
                <Typography className={classes.paperTitle} variant="h4">
                  {t('routes.home.plans.items.1.title')}
                </Typography>
                <Typography variant="body2">
                  {t('routes.home.plans.items.1.description')}
                </Typography>
              </Paper>
            </Fade>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Fade bottom delay={200} ssrReveal>
              <Paper className={classes.paper} elevation={4}>
                <StaticImage
                  alt={t('routes.home.plans.items.2.title')}
                  className={classes.paperImg}
                  height={64}
                  src="../../assets/img/home-plans-2.png"
                  width={64}
                />
                <Typography className={classes.paperTitle} variant="h4">
                  {t('routes.home.plans.items.2.title')}
                </Typography>
                <Typography variant="body2">
                  {t('routes.home.plans.items.2.description')}
                </Typography>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
        <Button color="secondary" size="large" variant="contained">
          <Link to={ROUTES.PRICES}>
            {t('routes.home.plans.readMore')}
          </Link>
        </Button>
      </Box>
    </Container>
  );
};

export default Plans;
