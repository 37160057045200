import { useTranslation } from 'react-i18next';

import AppLayout from 'app/AppLayout/AppLayout';
import SEO from 'components/seo';
import Section from 'components/Section/Section';
import PlansBackground from 'assets/img/home-plans-background.jpg';
import CoverImage from './CoverImage';
import WhatWeContribute from './WhatWeContribute';
import HowWeOperate from './HowWeOperate';
import ClientExperiences from './ClientExperiences';
import CommonProblems from './CommonProblems';
import Plans from './Plans';
import Contact from './Contact';

const Home = () => {
  const { t } = useTranslation();
  return (
    <AppLayout>
      <SEO
        description={t('routes.home.meta.description')}
        title={t('routes.home.meta.title')}
      />
      <Section
        backgroundColorFrom="#fff"
        backgroundColorTo="#fff"
        content={<CoverImage />}
        disablePadding
        id="cover-image"
      />
      <Section
        backgroundColorFrom="#fff"
        backgroundColorTo="#fff"
        description={t('routes.home.whatWeDo.description')}
        id="que-hacemos"
        title={t('routes.home.whatWeDo.title')}
      />
      <Section
        backgroundColorFrom="#f7f6f2"
        backgroundColorTo="#eddfd3"
        content={<CommonProblems />}
        id="problemas-psicologicos-mas-frecuentes"
        title={t('routes.home.commonProblems.title')}
      />
      <Section
        backgroundColorFrom="#fff"
        backgroundColorTo="#fff"
        content={<WhatWeContribute />}
        id="que-aportamos"
        title={t('routes.home.whatWeContribute.title')}
      />
      <Section
        backgroundColorFrom="#f7f6f2"
        backgroundColorTo="#eddfd3"
        backgroundOpacity={0.5}
        content={<HowWeOperate />}
        description={t('routes.home.howWeOperate.description')}
        id="como-funcionamos"
        title={t('routes.home.howWeOperate.title')}
      />
      <Section
        backgroundColorFrom="#ffffff"
        backgroundColorTo="#ffffff"
        backgroundOpacity={0.5}
        content={<ClientExperiences />}
        description={t('routes.home.clientExperiences.description')}
        id="experiencia-clientes"
        title={t('routes.home.clientExperiences.title')}
      />
      <Section
        backgroundImage={PlansBackground}
        content={<Plans />}
        description={t('routes.home.plans.description')}
        id="planes"
        title={t('routes.home.plans.title')}
      />
      <Section
        backgroundColorFrom="#fff"
        backgroundColorTo="#fff"
        content={<Contact />}
        description={t('routes.home.contact.description')}
        id="contacta"
        title={t('routes.home.contact.title')}
      />
    </AppLayout>
  );
};

export default Home;
