/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  divider: {
    width: '75%',
  },
  grid: {
    flexGrow: 1,
  },
  paper: {
    alignItems: 'center',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  paperBackground1Light: {
    '& hr': {
      background: '#fff',
    },
    background: theme.palette.primary.light,
  },
  paperBackground2: {
    '& hr': {
      background: '#fff',
    },
    background: theme.palette.secondary.main,
    color: '#fff',
  },
  paperBackground2Light: {
    background: theme.palette.secondary.light,
  },
  paperContent: {
    '& h4': {
      fontSize: '1.05rem',
      fontWeight: 600,
      textAlign: 'center',
      textTransform: 'uppercase',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.15rem',
      },
    },
    '& p, ul': {
      fontSize: '0.95rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  paperImg: {
    height: 274,
    objectFit: 'cover',
    width: '100%',
  },
  root: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 6),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 12),
    },
    margin: '0 auto',
    padding: theme.spacing(1, 2),
    textAlign: 'left',
  },
}));
