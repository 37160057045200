import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { ROUTES } from 'assets/data/actpsicologos-app';
import imgMd from 'assets/img/home-main-md.jpg';
import imgXs from 'assets/img/home-main-xs.jpg';
import { useMediaQueryTheme } from 'hooks';
import useStyles from './CoverImage.styles';

const CoverImage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isWidthUpMd = useMediaQueryTheme('md');

  return (
    <Box className={classes.root}>
      <img
        alt="actpsicologos"
        className={isWidthUpMd ? classes.imgMd : classes.imgXs}
        src={isWidthUpMd ? imgMd : imgXs}
      />
      <Box className={classes.content}>
        <Box className={classes.contentTop}>
          <Typography variant="h1">
            {t('routes.home.coverImage.title')}
          </Typography>
        </Box>
        <Box className={classes.contentBottom}>
          <Typography variant="h2">
            {t('routes.home.coverImage.description1')}
          </Typography>
          <Typography variant="h2">
            {t('routes.home.coverImage.description2')}
          </Typography>
          <Box className={classes.contentButton}>
            <Button
              color="secondary"
              size={isWidthUpMd ? 'large' : 'medium'}
              variant="contained"
            >
              <Link to={ROUTES.CONTACT}>
                {t('routes.home.coverImage.button')}
              </Link>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CoverImage;
