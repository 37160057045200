import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

import { useMediaQueryTheme } from 'hooks';
import { ROUTES } from 'assets/data/actpsicologos-app';
import useStyles from './ClientExperiences.styles';

const ClientExperiences = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const smMatches = useMediaQueryTheme('sm');

  const experiences = [...Array(6).keys()].map(index => ({
    title: t(`routes.home.clientExperiences.items.${index + 1}.title`),
  }));

  const renderExperience = ({ title }, index) => (
    <Grid key={index} item md={4} sm={6} xs={12}>
      <Paper className={classes.paper} elevation={10}>
        <Typography variant="h5">
          {`"${title}"`}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Container className={classes.root} maxWidth="lg">
      {smMatches
        ? (
          <Grid
            alignItems="center"
            className={classes.grid}
            container
            direction="row"
            justifyContent="center"
            spacing={4}
          >
            {experiences.map(renderExperience)}
          </Grid>
        )
        : (
          <>
            {renderExperience(experiences[0], 0)}
            <Accordion
              className={classes.accordion}
              expanded={expanded}
              onChange={() => {
                setExpanded(!expanded);
              }}
            >
              <AccordionSummary
                aria-controls="ver-mas-experiencias"
                expandIcon={expanded ? <FiMinusCircle /> : <FiPlusCircle />}
              >
                <Typography className={classes.heading}>
                  {expanded
                    ? t('routes.home.clientExperiences.seeLess')
                    : t('routes.home.clientExperiences.seeMore')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  alignItems="center"
                  className={classes.grid}
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={4}
                >
                  {experiences.slice(1, experiences.length).map(renderExperience)}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      <Button className={classes.button} color="secondary" size="large" variant="contained">
        <Link to={ROUTES.TEAM}>
          {t('routes.home.clientExperiences.readMore')}
        </Link>
      </Button>
    </Container>
  );
};

export default ClientExperiences;
